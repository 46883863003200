import { defineComponent } from 'vue';
import { useScroll } from '@/composables/use-scroll';
import config from '@/configs';
import { MenuList } from '@/constant/menu';
import { blockScroll, enableScroll } from '@/plugins/helper';
import { useMenu } from '~/composables/use-menu';
export default defineComponent({
    setup: function () {
        var _a = useMenu(), isShowInformationMenu = _a.isShowInformationMenu, isShowPickupMenu = _a.isShowPickupMenu;
        return {
            isShowInformationMenu: isShowInformationMenu, isShowPickupMenu: isShowPickupMenu
        };
    },
    data: function () {
        return {
            instagramUrl: config.sns.instagram,
            twitterUrl: config.sns.twitter,
            isMenuActive: false,
            isShowMenu: true,
            width: 0,
            menuList: MenuList
        };
    },
    watch: {
        $route: function () {
            this.isMenuActive = false;
        }
    },
    mounted: function () {
        var _this = this;
        var _a = useScroll(), isShowMenu = _a.isShowMenu, init = _a.init, onResize = _a.onResize;
        this.isShowMenu = isShowMenu;
        this.width = window.innerWidth;
        init();
        onResize(function () {
            _this.width = window.innerWidth;
            if (window.innerWidth >= 1024) {
                if (document.documentElement.style.overflow) {
                    enableScroll();
                }
                _this.isMenuActive = false;
            }
        });
    },
    methods: {
        toggleMenu: function () {
            if (this.width >= 1024) {
                return;
            }
            this.isMenuActive = !this.isMenuActive;
            if (this.isMenuActive) {
                blockScroll();
            }
            else {
                enableScroll();
            }
        },
        handleClickMenuItem: function (hash) {
            this.toggleMenu();
            var name = this.$route.name;
            if (name === 'index') {
                this.$scrollTo(hash + "-section");
            }
            else {
                this.$router.push("/" + hash);
            }
        }
    }
});
