import { defineComponent, onMounted, ref } from 'vue';
import { useSns } from '@/composables/use-sns';
import config from '@/configs';
export default defineComponent({
    name: 'CShareSns',
    setup: function () {
        var SnsHashTag = '#ボールパークファンタジア';
        var shareLineLink = ref('');
        var shareTwitterLink = ref('');
        var shareFacebookLink = ref('');
        var url = config.shareUrl + '\n';
        var snsContent = 'BALLPARK FANTASIA（ボールパークファンタジア）';
        onMounted(function () {
            var _a = useSns(), shareLine = _a.shareLine, shareTwitter = _a.shareTwitter, shareFacebook = _a.shareFacebook;
            var isSp = window.innerWidth < 768;
            shareLineLink.value = shareLine({ url: url, message: snsContent, isSp: isSp, hashtags: SnsHashTag });
            shareTwitterLink.value = shareTwitter({ url: url, message: snsContent, hashtags: SnsHashTag });
            shareFacebookLink.value = shareFacebook({ url: url, message: snsContent });
        });
        return { shareFacebookLink: shareFacebookLink, shareLineLink: shareLineLink, shareTwitterLink: shareTwitterLink };
    }
});
