export default {
    title: 'よりみちマルシェ by BALLPARK FANTASIA',
    titleTemplate: '',
    description: '2020年のスタートから今年で5年目を迎えた「ボールパークファンタジア」。今年は7月、9月、11月の3回の開催としてパワーアップ。第2回目は、「いつもの公園でいつもと違う体験」をコンセプトに、横浜公園内に「マーケット」「キッチンカー」「アクティブ」の3つのカテゴリのお店が出店！みなさまのご来場をお待ちしております。',
    url: {
        dev: 'https://dev.ballparkfantasia.com',
        prod: 'https://ballparkfantasia.com'
    },
    locale: 'ja_JP',
    site_name: 'よりみちマルシェ by BALLPARK FANTASIA',
    version: '1.0',
    app_name: 'BPF',
    sns: {
        twitter: 'https://twitter.com/bp_fantasia/',
        instagram: 'https://www.instagram.com/ballpark_fantasia/'
    },
    shareUrl: 'https://ballparkfantasia.com'
};
