import { render, staticRenderFns } from "./default.vue?vue&type=template&id=c5266b88&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutMenu: require('/home/circleci/repo/frontend/components/layout/menu.vue').default,LayoutShareSns: require('/home/circleci/repo/frontend/components/layout/share-sns.vue').default,LayoutBackToTop: require('/home/circleci/repo/frontend/components/layout/back-to-top.vue').default,LayoutFooter: require('/home/circleci/repo/frontend/components/layout/footer.vue').default})
